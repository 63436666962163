<template>
  <div>
    <b-card>
      <template #footer>
        <b-button
          type="reset"
          variant="secondary"
          @click="cancelForm"
        >
          Back
        </b-button>
      </template>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h4>{{ formTitle }}: {{ planName }}</h4>
        <b-button @click="openEditForm">
          Edit
        </b-button>
      </div>
    </b-card>

    <b-tabs pills>
      <b-tab
        lazy
        title="Payment Rate"
      >
        <lp-datatable-card-rate-view
          ref="paymentRateDetailRef"
          title="Payment Rate"
          :extra-parameter="extraParams"
          store-module="paymentRateExclusion"
          store-data-endpoint="getPaymentRateByPlanName"
          store-getter-endpoint="getPaymentRateByPlanName"
          pagination-state-key="paymentRateByPlanName"
          :features="loadedConfig.datatableFeatures"
          default-search-field="payment_method"
          default-sort-field="created_at"
          :search-options-dropdown="loadedConfig.searchOptsDropdown"
          :conf="loadedConfig"
        />
      </b-tab>
      <b-tab
        title="Exclusion Rules"
      >
        <lp-datatable-card-view
          ref="paymentRateExclusionRulesRef"
          title="Exclusion Rules"
          :extra-parameter="extraParams"
          store-module="paymentRateExclusion"
          store-getter-endpoint="getExclusionPlanRules"
          store-data-endpoint="getExclusionPlanRules"
          pagination-state-key="exclusionPlanRules"
          :features="loadedConfig2.datatableFeatures"
          default-search-field="payment_method"
          default-sort-field="created_at"
          :search-options-dropdown="loadedConfig2.searchOptsDropdown"
          :conf="loadedConfig2"
        />
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>

import LpDatatable from '@/leanpay-components/LPDataTable.vue'
import LpDatatableCardRateView from '@/leanpay-components/LPDatatableCardRateView.vue'
import LpDatatableCardView from '@/leanpay-components/LPDatatableCardView.vue'
import Config2 from '@/views/administration/plans/exclusionRulesFormConfig'
import Config from '@/views/administration/plans/paymentRateFormConfig'

export default {
  components: { LpDatatable, LpDatatableCardView, LpDatatableCardRateView },
  data() {
    return {
      actualForm: {},
      formTitle: 'View Plan',
      planName: '',
      extraParams: { plan_name: '' },
    }
  },
  computed: {
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    loadedConfig2() {
      if (Config2) {
        return Config2
      }
      return {}
    },
  },
  async mounted() {
    await this.$store.dispatch('subscriptionsPlan/getSubscriptionPlanById', this.$route.params.id).then(({ data }) => {
      this.actualForm = data.data
      this.planName = data.data.plan_name
      this.extraParams.plan_name = data.data.plan_name

      const breadcrumbUpdatePayload = [
        { title: 'Plans' },
        { title: this.formTitle },
        { title: this.planName },
      ]
      this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

      this.$refs.paymentRateDetailRef.initDataGrid()
      this.$refs.paymentRateExclusionRulesRef.initDataGrid()
    })

    await this.$store.dispatch('paymentRateExclusion/getExclusionResource')
  },
  methods: {
    openEditForm() {
      this.$router.push({ name: 'administration-plans-form', params: { id: this.actualForm.id, mode: 'edit' } })
    },
    cancelForm() {
      this.$router.push({ name: this.parentPageNamedRoute })
    },
  },
}
</script>

<style>

</style>
