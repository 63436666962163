import { required } from '@vuelidate/validators'

const Config = {
  datatableFeatures: {
    buttons: {
      createButton: true,
      createButtonRoute: 'administration-plans-new',
      refresh: true,
    },
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'payment_method', text: 'Payment Method' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Plan',
        view: 'View Plan',
        remove: 'Remove Plan',
        create: 'Create New Plan',
      },

      // {
      //   "plan_name": "string",
      //   "plan_charges": 0,
      //   "fpx_charges": 0,
      //   "credit_card_charges": 0,
      //   "payment_form": 0,
      //   "bill_form": 0,
      //   "domain": 0,
      //   "short_url": "string",
      //   "store": 0,
      //   "catalog": 0,
      //   "product": 0,
      //   "api": "string",
      //   "credit_card_enable": true,
      //   "fpx_enable": true,
      //   "ewallet_enable": true,
      //   "bnpl_enable": true,
      //   "created_at": "2022-06-10T19:16:41.889566",
      //   "updated_at": "2022-06-10T19:16:41.889624"
      // }
      form: [{
        formContext: 'Account',
        formName: 'account',
        elem: [
          {
            key: 'plan_name',
            fieldName: 'Plan Name',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 6,
            },
          },
          {
            key: 'plan_charges',
            fieldName: 'Plan Charges',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 6,
            },
          },
          {
            key: 'fpx_charges',
            fieldName: 'FPX Charges',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'credit_card_charges',
            fieldName: 'Credit Card Charges',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'payment_form',
            fieldName: 'Payment Form',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'bill_form',
            fieldName: 'Bill Form',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 2,
            },
          },
          {
            key: 'product',
            fieldName: 'Product',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 2,
            },
          },
          {
            key: 'domain',
            fieldName: 'Domain',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 2,
            },
          },
          {
            key: 'store',
            fieldName: 'Store',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'catalog',
            fieldName: 'Catalog',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'api',
            fieldName: 'API',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            key: 'short_url',
            fieldName: 'Short URL',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 4,
            },
          },
          {
            customValidation: true,
            key: 'credit_card_enable',
            fieldName: 'Enable Credit Card ?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'fpx_enable',
            fieldName: 'Enable FPX?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'ewallet_enable',
            fieldName: 'Enable E-Wallet?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'bnpl_enable',
            fieldName: 'Enable BNPL?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: true, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12', pageRoute: 'administration-plans-view-form',
        },
        {
          enable: true, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12', pageRoute: 'administration-plans-form',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        // {
        //   "plan": "Standard Promo Plan",
        //   "payment_method": "SWITCH_STRIPE_CC",
        //   "rate_channel": "MAM Charge",
        //   "rate_type": "2",
        //   "provider_name": "Standard Promo Plan",
        //   "percentage": 2.5,
        //   "fixed_amount": 1,
        //   "minimum_amount_for_percentage": 10,
        //   "additional_fee_amount": 0.5,
        //   "add_additional_fee": false,
        //   "created_at": "2024-08-22T16:14:19",
        //   "updated_at": "2024-08-22T16:14:19",
        //   "record_status": 1
        // }
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },

        {
          key: 'payment_method', label: 'Payment Method', sortable: true, visible: true, showInDetails: false,
        },
        {
          key: 'rate_channel', label: 'Rate Channel', sortable: true, visible: true, showInDetails: false, type: 'paymentRateChannel',
        },
        {
          key: 'rate_type', label: 'Rate Type', sortable: true, visible: false, showInDetails: false,
        },
        {
          key: 'percentage', label: 'Percentage', sortable: true, visible: true, showInDetails: false, type: 'paymentRatePercentage',
        },
        {
          key: 'fixed_amount', label: 'Fixed Amount', sortable: true, visible: true, showInDetails: false, type: 'monetary',
        },
        {
          key: 'minimum_amount_for_percentage', label: 'Min Amount', sortable: true, visible: true, showInDetails: false, type: 'monetary',
        },
        {
          key: 'additional_fee', label: 'Additional Fee?', sortable: true, visible: true, showInDetails: false, type: 'paymentRateAdditionalFee',
        },
        {
          key: 'add_additional_fee', label: 'Additional Fee?', sortable: true, visible: false, showInDetails: true, type: 'boolFlag',
        },
        {
          key: 'additional_fee_amount', label: 'Additional Fee Amount', sortable: true, visible: false, showInDetails: true, type: 'monetary',
        },
        {
          key: 'record_status', label: 'Record Status', sortable: true, visible: false, showInDetails: true,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false, type: 'date',
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: false,
        },
      ],
    }
  },
}

export default Config
