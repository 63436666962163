<template>
  <div>
    <b-card no-body>
      <template #header>
        <h4 class="mr-auto mb-0">
          {{ title }}
        </h4>
      </template>

    </b-card>

    <b-row>
      <b-col
        v-for="(item, idx) in GridData"
        :key="`grid-item-${idx}`"
        cols="6"
      >

        <b-card
          no-body
        >
          <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ item }}</pre>
          <template #footer>
            <b-button
              type="submit"
              variant="primary"
              @click="onSubmitExclusion(item)"
            >
              Submit
            </b-button>
          </template>
          <template #header>
            <h4 class="mr-auto mb-0 text-capitalize">
              {{ item.exclusion_type }}
            </h4>
          </template>

          <!-- convert to form component -->

          <div class="px-2">
            <b-form-group>
              <label>Excluded Payment Service</label>
              <v-select
                v-if="item.exclusion_type === 'payout'"
                v-model="item.payment_services"
                multiple
                placeholder="Please select"
                :options="payoutServicesExclusionOpts"
              />
              <v-select
                v-if="item.exclusion_type === 'transaction'"
                v-model="item.payment_services"
                multiple
                placeholder="Please select"
                :options="txnServiceExclusionOpts"
              />

            </b-form-group>

            <b-form-group>
              <label>Excluded Channel Codes</label>
              <v-select
                v-if="item.exclusion_type === 'payout'"
                v-model="item.channel_codes"
                multiple
                placeholder="Please select"
                :options="payoutChannelsExclusionOpts"
              />

              <v-select
                v-if="item.exclusion_type === 'transaction'"
                v-model="item.channel_codes"
                multiple
                placeholder="Please select"
                :options="txnChannelsExclusionOpts"
              />

            </b-form-group>

            <b-form-group>
              <label>Excluded Unique Codes</label>
              <v-select
                v-if="item.exclusion_type === 'payout'"
                v-model="item.unique_codes"
                multiple
                placeholder="Please select"
                :options="payoutUniquesExclusionOpts"
              />
              <v-select
                v-if="item.exclusion_type === 'transaction'"
                v-model="item.unique_codes"
                multiple
                placeholder="Please select"
                :options="txnUniquesExclusionOpts"
              />

            </b-form-group>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!--    {{ GridData }}-->

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import humaniseMixin from '@/common/humanise.mixin'
import LpdtField from '@/leanpay-components/LPDTField.vue'
import LpdtPagination from '@/leanpay-components/LPDTPagination.vue'

export default {
  components: {
    LpdtField, LpdtPagination, vSelect, flatPickr,
  },
  mixins: [humaniseMixin],
  props: {
    actionCondition: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
      default: '',
    },
    storeModule: {
      required: true,
      type: String,
    },
    selectableTable: {
      required: false,
      type: Boolean,
      default: false,
    },
    storeDataEndpoint: {
      required: true,
      type: String,
    },
    loadSystemConstant: {
      type: Boolean,
      default: false,
    },
    storeGetterEndpoint: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    currentSelectedStatus: {
      type: Object,
    },
    queryParam: {
      required: false,
      type: String,
    },
    rawDataKey: {
      required: false,
      type: String,
      default: 'getRawData',
    },
    defaultSortField: {
      required: false,
      type: String,
      default: 'created_at',
    },
    extraParameter: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    defaultSearchField: {
      required: true,
      type: String,
    },
    rawData: {
      required: false,
      type: Object,
    },
    paginationStateKey: {
      required: false,
      type: String,
    },
    searchOptionsDropdown: {
      required: false,
      type: Array,
    },
    conf: {
      required: false,
      type: Object,
    },
    pageConf: {
      required: false,
      type: Object,
    },
    features: {
      required: false,
      type: Object,
      default() {
        return {
          datatableFeatures: {
            buttons: {
              createButton: false,
              refresh: false,
            },
            perPageDropdown: true,
            filter: true,
            pagination: true,
            search: false,
          },
        }
      },
    },
  },
  data() {
    return {
      rangeDate: null,
      cardTitle: 'title',
      dataGridEndpoint: [],
      module: 'development',

      selectMode: 'multi',
      selected: [],

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortDesc: true,

      record_status: 1, // active only
      searchField: '',
      sortBy: 'desc',
      sort: '',
    }
  },
  computed: {
    payoutServicesExclusionOpts() {
      const list = this.$store.getters['paymentRateExclusion/getExclusionResource']('payout', 'services')
      if (Array.isArray(list)) {
        return list
      } return []
    },
    txnServiceExclusionOpts() {
      const list = this.$store.getters['paymentRateExclusion/getExclusionResource']('transactions', 'services')
      if (Array.isArray(list)) {
        return list
      } return []
    },
    payoutChannelsExclusionOpts() {
      const list = this.$store.getters['paymentRateExclusion/getExclusionResource']('payout', 'channels')
      if (Array.isArray(list)) {
        return list
      } return []
    },
    txnChannelsExclusionOpts() {
      const list = this.$store.getters['paymentRateExclusion/getExclusionResource']('transactions', 'channels')
      if (Array.isArray(list)) {
        return list
      } return []
    },
    payoutUniquesExclusionOpts() {
      const list = this.$store.getters['paymentRateExclusion/getExclusionResource']('payout', 'uniques')
      if (Array.isArray(list)) {
        return list
      } return []
    },
    txnUniquesExclusionOpts() {
      const list = this.$store.getters['paymentRateExclusion/getExclusionResource']('transactions', 'uniques')
      if (Array.isArray(list)) {
        return list
      } return []
    },
    processedDateRange() {
      let dateRange = this.rangeDate
      if (dateRange) {
        dateRange = dateRange.split(' to ')
        if (dateRange.length > 1) {
          return {
            startDate: dateRange[0],
            endDate: dateRange[1],
          }
        }
        return {
          startDate: dateRange[0],
          endDate: dateRange[0],
        }
      }
      return this.defaultDateRange
    },
    formSchema() {
      const list = this.$store.getters[`${this.storeModule}/${this.storeGetterEndpoint}`]
      // return Object.getOwnPropertyNames(list[0])
      const x = []
      for (const [key, value] of Object.entries(list[0])) {
        x.push({ name: key, type: typeof value })
      }
      return x
    },
    tableConfig() {
      if (this.conf) {
        return this.conf.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters[`${this.storeModule}/${this.rawDataKey}`](this.paginationStateKey)
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridSettings.recordsTotal
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      let x = this.tableConfig.tableHeader
      if (this.features.tableActionField && x.length > 0) {
        x = [
          ...x,
          {
            key: 'actions',
            label: 'Action',
            class: 'text-right',
            visible: true,
          },
        ]
      }
      if (this.actionCondition && this.extraParameter.invoice_status[0] === 'FAILED' && this.actionCondition === 'FAILED') {
        x = [
          ...x,
          {
            key: 'actions',
            label: 'Action',
            class: 'text-right',
            visible: true,
          },
        ]
      }

      if (this.selectableTable) {
        x.unshift({
          key: 'selected',
          label: '',
          sortable: false,
          visible: true,
          type: 'visualcheckbox',
        })
      }

      return x.filter(y => y.visible)
    },
    TableActions() {
      const x = this.tableConfig.tableAction
      return x.filter(y => y.enable)
    },
    sortObj() {
      const obj = {
        parameter_name: this.sort,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters[`${this.storeModule}/${this.storeGetterEndpoint}`]
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters[`${this.storeModule}/${this.rawDataKey}`](this.paginationStateKey)
      if (list !== null) {
        return {
          draw: list.draw,
          recordsTotal: list.record_total,
          next_page: list.next_page,
          next_page_length: list.next_page_length,
          next_page_start: list.next_page_start,
          previous_page_length: list.previous_page_length,
          previous_page_start: list.previous_page_start,
          previouse_page: list.previouse_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },
  },
  async mounted() {
    this.isBusy = false

    this.searchField = this.defaultSearchField
    this.sort = this.defaultSortField

    if (this.loadSystemConstant) {
      await this.$store.dispatch('systemConstants/getSystemConstants')
    }

    let payload = {
      start_date: this.processedDateRange.startDate,
      end_date: this.processedDateRange.endDate,
      limit: this.tableSettings.perPage,
      skip: 0,
      search: {
        search_enable: false,
        search_key: '',
        search_column: '',
        search_replace_word_enable: true,
        search_word_replace: ' ',
        search_word_replace_to: '_',
      },
      sort: this.sortObj,
      ...this.extraParameter,
    }

    if (this.queryParam && typeof this.queryParam === 'string') {
      const y = this.queryParam
      const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

      payload = { ...obj, ...payload }
    }

    if (this.currentSelectedStatus) {
      payload = {
        ...payload,
        search: {
          search_enable: true,
          search_key: [this.currentSelectedStatus.q],
          search_column: [this.currentSelectedStatus.field],
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
      }
    }
    await this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload)
  },
  methods: {
    onSubmitExclusion(item) {
      this.$swal.fire({
        customClass: {
          confirmButton: 'btn btn-sm btn-primary',
          cancelButton: ' btn btn-sm btn-secondary ml-1 ',
        },
        html: 'Are you sure?',
        title: 'Confirmation',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        denyButtonText: 'Cancel',
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const payload = {
            ...item,
          }
          this.$store.dispatch('paymentRateExclusion/updateExclusionPlanRules', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$swal.fire({
                customClass: {
                  confirmButton: 'btn btn-sm btn-primary',
                  cancelButton: ' btn btn-sm btn-secondary ml-1 ',
                },
                html: 'Exclusion rules updated successfully.',
                title: 'Success',
                allowOutsideClick: false,
                allowEscapeKey: false,
              })
              this.initDataGrid()
            }
          })
        }
      })
    },

    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs[`selectableTable-${this.id}`].selectAllRows()
    },
    clearSelected() {
      this.$refs[`selectableTable-${this.id}`].clearSelected()
    },
    bulkAction(type) {
      if (type === 'reconcile') {
        this.$emit('bulk-reconcile-clicked', this.selected)
      }
    },
    failAdjustment(id, actionCondition) {
      if (actionCondition === 'FAILED') {
        this.$emit('adjustment-clicked', id)
      }
    },
    reconcile(invoice_no, actionCondition) {
      if (actionCondition === 'NESTED_INVOICE_NO') {
        this.$emit('reconcile-clicked', invoice_no)
      }
    },
    clearDateSearch() {
      this.rangeDate = null
      this.initDataGrid()
    },
    getTableKey(x) {
      return `cell(${x.key})`
    },
    displaySystemConstantsLabel(key, id = null) {
      const recordStatusList = this.$store.getters['systemConstants/getSystemConstantsByKey'](key)
      if (Array.isArray(recordStatusList) && recordStatusList.length && id !== null) {
        const x = recordStatusList.filter(el => el.value === parseInt(id, 10))
        // return x
        // return this.humanise(
        //   x[0].name,
        // )
      }
      return 'NA'
    },
    editRow(itemId) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('edit', itemId)
      }
      this.$emit('editBtn-clicked', itemId)
      const x = this.TableActions.filter(el => el.action === 'edit')
      if (x[0].pageRoute) {
        this.$router.push({
          name: x[0].pageRoute,
          params: { id: itemId, mode: 'edit' },
        })
      } else {
        this.$emit('editBtn-clicked', itemId)
      }
    },
    deleteRow(itemId) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('DT deleteRow', itemId)
      }
      this.$emit('deleteBtn-clicked', itemId)
    },
    actionBtn(itemId, actionName, row) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('DT actionBtn', itemId)
      }

      this.$emit('actionBtn-clicked', { rowData: row, actionName, value: itemId })
    },
    viewRow(itemId) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('DT viewRow', itemId)
      }

      const x = this.TableActions.filter(el => el.action === 'view')
      if (x[0].pageRoute) {
        this.$router.push({
          name: x[0].pageRoute,
          params: { id: itemId, mode: 'view' },
        })
      } else {
        this.$emit('viewBtn-clicked', itemId)
      }
    },
    extraButton(actionName) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('DT extraBtn', actionName)
      }

      this.$emit('extraBtn-clicked', actionName)
    },
    tableStatusFilterButtonClick(actionName, index) {
      console.log(this.features)
      this.features.tableStatusFilterButton.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.active = false
      })
      this.features.tableStatusFilterButton[index].active = true
      this.$emit('status-filter-btn-clicked', actionName)
      console.log(index)
    },
    txnTypeFilterButtonClick(actionName, index) {
      console.log(this.features)
      this.features.txnTypeFilter.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.active = false
      })
      this.features.txnTypeFilter[index].active = true
      this.$emit('txn-type-filter-btn-clicked', actionName)
      console.log(index)
    },
    tablePaymentProviderFilterButtonClick(actionName, index) {
      console.log(index, actionName)
      this.features.tablePaymentProviderFilterButton.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.active = false
      })
      this.features.tablePaymentProviderFilterButton[index].active = true
      this.$emit('payment-provider-btn-clicked', actionName)
    },
    create() {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('create')
      }
      this.$router.push({
        name: this.features.buttons.createButtonRoute,
      })
    },
    pagination(e) {
      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: (e - 1) * this.tableSettings.perPage,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        ...this.extraParameter,
      }
      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.isBusy = false
        this.currentPage = e
      })
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      // alert(ctx.sortBy)

      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: 0,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: ctx.sortBy,
          sort_type: this.sortDesc ? 'desc' : 'asc',
        },
        ...this.extraParameter,
      }

      if (this.searchField !== '') {
        payload = {
          start_date: this.processedDateRange.startDate,
          end_date: this.processedDateRange.endDate,
          limit: this.tableSettings.perPage,
          skip: 0,
          record_status: 1,
          search: {
            search_enable: false,
            search_key: '',
            search_column: '',
            search_replace_word_enable: true,
            search_word_replace: ' ',
            search_word_replace_to: '_',
          },
          sort: {
            parameter_name: ctx.sortBy,
            sort_type: this.sortDesc ? 'desc' : 'asc',
          },
          ...this.extraParameter,
        }
      }

      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid() {
      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: 0,
        search: {
          search_enable: true,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        ...this.extraParameter,
      }
      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }

      if (this.currentSelectedStatus) {
        payload = {
          ...payload,
          search: {
            search_enable: true,
            search_key: [this.searchTerm],
            search_column: [this.searchField, this.currentSelectedStatus.field],
            search_replace_word_enable: true,
            search_word_replace: ' ',
            search_word_replace_to: '_',
          },
        }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    refreshButton(endpoint = null) {
      this.$emit('refresh-button-clicked', true)
      if (Array.isArray(endpoint) && endpoint.length > 0) {
        this.initDataGrid(endpoint)
      } else {
        this.initDataGrid()
      }
    },
    initDataGrid() {
      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: 0,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        ...this.extraParameter,
      }

      // console.log('payload', payload)

      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }
      if (this.currentSelectedStatus) {
        payload = {
          ...payload,
          search: {
            search_enable: true,
            search_key: [this.currentSelectedStatus.q],
            search_column: [this.currentSelectedStatus.field],
            search_replace_word_enable: true,
            search_word_replace: ' ',
            search_word_replace_to: '_',
          },
        }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false

        this.searchTerm = ''
      })
    },
  },
}
</script>

<style>
.show-details.col-width { width: 20px; padding: 0}
</style>
